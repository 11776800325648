
import { defineComponent, watch } from "vue";
import KTTopbar from "@/layout/header/Topbar.vue";

import {
  headerWidthFluid,
  headerLeft,
  asideDisplay,
} from "@/core/helpers/config";
import { useStore } from "vuex";
import KtMenu from "@/layout/header/Menu.vue";

export default defineComponent({
  name: "KTHeader",
  props: {
    title: String,
  },
  computed: {
    balance() {
      let store = useStore();
      return store.getters.currentUser.balance;
    },
    manager() {
      let store = useStore();
      return store.getters.currentUser.manager;
    },
  },
  components: {
    KtMenu,
    KTTopbar,
  },

  setup() {
    return {
      headerWidthFluid,
      headerLeft,
      asideDisplay,
    };
  },
});
